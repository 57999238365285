<template>
      <div class="profile">
            <!-- <entrance :sitedata="true" @changTip="changTip" ref="entrance"></entrance> -->
            <entranceClient :sitedata="true" @changTip="changTip" ref="entrance"></entranceClient>
            <div class="title flex-align-center">个人资料</div>
            <div class="main flex-align-between">
                  <div class="card flex-align-center">
                        <div class="flex-column-center">
                              <ul>
                                    <li class="flex-align-center">
                                          <el-upload class="avatar-uploader" action="action" :show-file-list="false"
                                                :on-success="handleAvatarSuccess" :http-request="upload"
                                                :before-upload="beforeAvatarUpload">
                                                <div class="tacitly">
                                                      <img v-if="succeed" :src="imageUrl" class="avatar" />
                                                      <img src="../../assets/user/user.png" v-else />
                                                </div>
                                          </el-upload>
                                    </li>
                                    <li class="flex-align-center flex-column-center">
                                          <span>点击修改头像</span>
                                          <span>（仅支持.jpg图片类型，不能大于3M）</span>
                                    </li>
                                    <li class="flex-align-between">
                                          <span>学 校</span>
                                          <div>{{ schoolName }}</div>
                                    </li>
                                    <li class="flex-align-between">
                                          <span>班 级</span>
                                          <div>{{ className }}</div>
                                    </li>
                              </ul>
                        </div>
                  </div>
                  <div class="amend flex-align-center">
                        <ul>
                              <li class="flex-align-between">
                                    <div class="item flex-between">
                                          <div class="imgbox flex-align-center">
                                                <img class="itemImg" src="../../assets/user/name.png" alt="" />
                                          </div>
                                          <div class="itemText">姓名<i></i></div>
                                    </div>
                                    <div class="content flex-align ">{{ userName }}</div>
                                    <div class="explain">
                                          <div class="explainT flex-align">
                                                <img src="../../assets/user/compile.png" @click="Gocompile(1)" />
                                                <span @click="Gocompile(1)">修改姓名</span>
                                          </div>
                                          <div class="explainB"></div>
                                          <!-- *姓名一年只能修改一次，请谨慎修改 -->
                                    </div>
                              </li>
                              <li class="flex-align-between">
                                    <div class="item flex-between">
                                          <div class="imgbox flex-align-center">
                                                <img class="itemImg" src="../../assets/user/gender.png" alt="" />
                                          </div>
                                          <div class="itemText">性别<i></i></div>
                                    </div>
                                    <div class="content flex-align">
                                          {{ gender == 0 ? "女" : "男" }}
                                    </div>
                                    <div class="explain">
                                          <div class="explainT flex-align">
                                                <img src="../../assets/user/compile.png" @click="Gocompile(2)" />
                                                <span @click="Gocompile(2)">修改性别</span>
                                          </div>
                                          <div class="explainB"></div>
                                    </div>
                              </li>
                              <li class="flex-align-between" v-if="false">
                                    <div class="item flex-between">
                                          <div class="imgbox flex-align-center">
                                                <img class="itemImg" src="../../assets/user/pone.png" alt="" />
                                          </div>
                                          <div class="itemText">手机号<i></i></div>
                                    </div>
                                    <div class="content flex-align">{{ phone }}</div>
                                    <div class="explain">
                                          <div class="explainT flex-align">
                                                <img src="../../assets/user/compile.png" @click="Gocompile(3)" />
                                                <span @click="Gocompile(3)">修改手机号</span>
                                          </div>
                                          <div class="explainB">*手机号一年只能修改一次，请谨慎修改</div>
                                    </div>
                              </li>
                              <li class="flex-align-between">
                                    <div class="item flex-between">
                                          <div class="imgbox flex-align-center">
                                                <img class="itemImg" src="../../assets/user/pwd.png" alt="" />
                                          </div>
                                          <div class="itemText">密码<i></i></div>
                                    </div>
                                    <div class="content flex-align">{{ pwd }}</div>
                                    <div class="explain">
                                          <div class="explainT flex-align">
                                                <img src="../../assets/user/compile.png" @click="Gocompile(4)" />
                                                <span @click="Gocompile(4)">修改密码</span>
                                          </div>
                                          <div class="explainB"></div>
                                    </div>
                              </li>
                        </ul>
                  </div>
            </div>
            <div class="dialog">
                  <el-dialog :visible.sync="centerDialogVisible" width="30%" :close-on-click-modal="false" center
                        @close="resetForm('ruleForm')">
                        <div class="dialogbox">
                              <div class="dialogtitle flex-align-center">{{ title }}</div>
                              <div class="content flex-align-center">
                                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm"
                                          label-width="100px" class="demo-ruleForm">
                                          <el-form-item prop="name" v-if="title == '修改姓名'" class="name">
                                                <el-input v-model.trim="ruleForm.name" @input="judge(ruleForm.name)">
                                                </el-input>
                                          </el-form-item>
                                          <el-form-item class="resource" v-if="title == '修改性别'">
                                                <el-radio-group v-model="ruleForm.resource" size="medium"
                                                      @change="touchRadio">
                                                      <el-radio border label="1">男</el-radio>
                                                      <el-radio border label="0">女</el-radio>
                                                </el-radio-group>
                                          </el-form-item>
                                          <el-form-item prop="phone" v-if="title == '修改手机号'" class="phonebox">
                                                <el-input v-model.trim="ruleForm.phone" placeholder="输入新的手机号"
                                                      maxlength="11" @input="judge(ruleForm.phone)">
                                                </el-input>
                                          </el-form-item>
                                          <el-form-item prop="phonecode" v-if="title == '修改手机号'" class="phonecode">
                                                <el-input v-model.trim="ruleForm.phonecode" placeholder="输入验证码"
                                                      @input="judge(ruleForm.phonecode)">
                                                </el-input>
                                                <div class="codeBtn flex-align-center">
                                                      <span class="flex-align-center"
                                                            @click="Verification ? gaincode(60000) : ''"
                                                            v-if="Verification">获取验证码</span>
                                                      <span class="flex-align-center" v-else>{{ timer }}s</span>
                                                </div>
                                          </el-form-item>
                                          <el-form-item prop="originalPwd" v-if="title == '修改密码'" class="originalPwd">
                                                <el-input v-model.trim="ruleForm.originalPwd" placeholder="输入原密码"
                                                      @input="judge(ruleForm.originalPwd)">
                                                </el-input>
                                          </el-form-item>
                                          <el-form-item prop="newPwd" v-if="title == '修改密码'" class="newPwd">
                                                <el-input v-model.trim="ruleForm.newPwd" placeholder="输入新密码"
                                                      @input="judge(ruleForm.newPwd)">
                                                </el-input>
                                                <p class="default" v-if="default_newPwd">
                                                      提示：密码需设置6-8位数字、字母、特殊符号，两种及以上组合
                                                </p>
                                          </el-form-item>
                                          <el-form-item>
                                                <div class="flex-align-between">
                                                      <div class="cancel btn flex-align-center"
                                                            @click="centerDialogVisible = false">
                                                            取消
                                                      </div>
                                                      <div class="btn flex-align-center"
                                                            :class="satisfy ? 'save' : 'saveF'"
                                                            @click="satisfy ? submitForm('ruleForm') : ''">
                                                            保存
                                                      </div>

                                                </div>
                                          </el-form-item>
                                    </el-form>
                              </div>
                        </div>
                  </el-dialog>
            </div>
            <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
      </div>
</template>

<script>
      import entranceClient from "../../Common_components/head/entranceClient";
      import {
            mapMutations
      } from "vuex";
      import subscribe from "../../Common_components/Popup/subscribe.vue";
      export default {
            data() {
                  var checkName = (rule, value, callback) => {
                        if (!value) {
                              return callback(new Error("姓名不能为空"));
                        }
                        if (value.length > 26) {
                              return callback(new Error("姓名不能超过26个字"));
                        } else {
                              callback();
                        }
                  };
                  var checkPhone = (rule, value, callback) => {
                        if (!value) {
                              this.codeStatus = false;
                              return callback(new Error("手机号不能为空"));
                        }
                        if (!/^1[3456789]\d{9}$/.test(value)) {
                              this.codeStatus = false;
                              return callback(new Error("不是一个正确的手机号"));
                        } else {
                              this.codeStatus = true;
                              callback();
                        }
                  };
                  var checkPhonecode = (rule, value, callback) => {
                        if (!value) {
                              return callback(new Error("验证码不能为空"));
                        }
                        if (this.gainPhonecode) {
                              return callback(new Error("验证码不正确"));
                        } else {
                              callback();
                        }
                  };
                  var checkoriginalPwd = async (rule, value, callback) => {
                        console.log('value', value)
                        let data = {
                              resetPassword: value
                        }
                        if (!value) {
                              return callback(new Error("密码不能为空"));

                        } else {
                              let resData = await this.$Api.Profile.checkUserOldPwd(data);
                              if (resData.data) {
                                    callback();
                              } else {
                                    return callback(new Error("密码不正确"));
                              }
                        }
                  };
                  var checknewPwd = (rule, value, callback) => {
                        if (!value) {
                              this.default_newPwd = false;
                              return callback(new Error("密码不能为空"));
                        }
                        if (
                              !/(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*()_.]+)$)^[\w~!@#$%^&*()_.]{6,8}$/.test(
                                    value
                              )
                        ) {
                              this.default_newPwd = false;
                              callback(
                                    new Error("密码需设置6-8位数字、字母、特殊符号，两种及以上组合")
                              );
                        } else {
                              callback();
                        }
                  };

                  return {
                        titleGroup: {
                              1: "修改姓名",
                              2: "修改性别",
                              3: "修改手机号",
                              4: "修改密码",
                        },

                        title: "",
                        imageUrl: "",
                        schoolName: "",
                        schoolId: "",
                        classId: "",
                        className: "",
                        userName: "",
                        gender: "",
                        phone: "",
                        pwd: "",
                        centerDialogVisible: false,
                        default_newPwd: true,

                        ruleForm: {
                              name: "",
                              resource: "",
                              phone: "",
                              phonecode: "",
                              originalPwd: "",
                              newPwd: "",
                        },
                        gainPhonecode: true,
                        rules: {
                              //名字
                              name: [{
                                    validator: checkName,
                                    trigger: "blur",
                              }, ],
                              //手机号
                              phone: [{
                                    validator: checkPhone,
                                    trigger: "blur",
                              }, ],
                              //验证码
                              phonecode: [{
                                    validator: checkPhonecode,
                                    trigger: "blur",
                              }, ],
                              //原密码
                              originalPwd: [{
                                    validator: checkoriginalPwd,
                                    trigger: "blur",
                              }, ],
                              //当前修改密码
                              newPwd: [{
                                    validator: checknewPwd,
                                    trigger: "blur",
                              }, ],
                        },
                        Verification: true,
                        codeStatus: true,
                        timer: 60,
                        succeed: false,
                        satisfy: false,
                        centerDialogVisible2: false,
                        originalPwdShow: false,
                  };
            },
            components: {
                  entranceClient,
                  subscribe,
            },
            //方法
            methods: {
                  ...mapMutations(["hint_data"]),
                  changTip(value) {
                        if (value) {
                              this.hint_data("暂无活动");
                              this.centerDialogVisible2 = true;
                        }
                  },
                  clear(bool) {
                        this.centerDialogVisible2 = bool;
                  },
                  //长传头像地址
                  handleAvatarSuccess(res, file) {
                        this.imageUrl = URL.createObjectURL(file.raw);
                  },
                  async upload(value) {
                        let _this = this;
                        let formData = new FormData();
                        formData.append("avatar", value.file);
                        // formData.append("fileName", value.file.name);
                        formData.append("fileName", 'ssyz_' + new Date().getTime() + '.jpg');
                        this.$axios
                              .post(
                                    "https://yunapi.cnuzi.com/api/learn/user/avatar",
                                    // "https://apitest.lezhireading.com/api/learn/user/avatar",
                                    formData, {
                                          headers: {
                                                "Content-Type": "multipart/form-data",
                                          },
                                    }
                              )
                              .then(function (res) {
                                    _this.$refs.entrance.getUser();
                                    _this.succeed = true;
                              });
                  },
                  //图片上传条件
                  beforeAvatarUpload(file) {
                        const isJPG = file.type === "image/jpeg";

                        const isLt2M = file.size / 1024 / 1024 < 3;

                        if (!isJPG) {
                              this.$message.error("上传头像图片只能是 JPG 格式!");
                        }
                        if (!isLt2M) {
                              this.$message.error("上传头像图片大小不能超过 3MB!");
                        }
                        this.file = file;
                        // console.log('file', file)
                        return isJPG && isLt2M;
                  },
                  //选择修改类目
                  Gocompile(index) {
                        this.title = this.titleGroup[index];
                        this.centerDialogVisible = true;
                  },
                  //提交表单
                  submitForm(formName) {
                        this.$refs[formName].validate((valid) => {
                              if (valid) {
                                    if (this.title == "修改手机号") {
                                          this.updateUserPhone();
                                    } else if (this.title == "修改密码") {
                                          this.updateUserPassword();
                                    } else if (this.title == "修改姓名") {
                                          this.updateUserInfo(1);
                                    } else if (this.title == "修改性别") {
                                          this.updateUserInfo(2);
                                    }
                              } else {
                                    return false;
                              }
                        });
                  },
                  //修改信息
                  async updateUserInfo(type) {
                        let _this = this;
                        let sex = this.gender == "男" ? "1" : "0";
                        let data = {
                              name: this.ruleForm.name || this.userName,
                              gender: this.ruleForm.resource || sex,
                        };
                        console.log("data", data);
                        let resData = await this.$Api.Profile.updateUserInfo(data);
                        if (resData.data.data == "修改成功") {
                              this.centerDialogVisible = false;
                              this.$message({
                                    message: type == 1 ? "姓名修改成功" : "性别修改成功",
                                    center: true,
                                    type: "success",
                              });
                              _this.$refs.entrance.getUser();
                              _this.userName =
                                    _this.ruleForm.name != "" ? _this.ruleForm.name : _this.userName;
                              _this.gender =
                                    _this.ruleForm.resource != "" ?
                                    _this.ruleForm.resource :
                                    _this.gender;
                        }
                  },
                  //重置
                  resetForm(formName) {
                        this.satisfy = false;
                        this.default_newPwd = true;
                        if (this.title == "修改性别") return;
                        this.$refs[formName].resetFields();
                  },
                  //获取验证码
                  async gaincode(intervalTime) {
                        this.$refs.ruleForm.validateField("phone");
                        if (this.ruleForm.phone == "" || !this.codeStatus) return false;

                        let data = {
                              mobile: this.ruleForm.phone,
                              type: "update",
                        };
                        let resData = await this.$Api.Profile.getsendCode(data);

                        if (resData.code == 200) {
                              this.Verification = false;
                              this.gainPhonecode = false;
                              this.phone = this.ruleForm.phone;
                              let auth_timer = setInterval(() => {
                                    this.timer--;
                                    if (this.timer <= 0) {
                                          this.Verification = true;
                                          clearInterval(auth_timer);
                                          this.timer = 60;
                                    }
                              }, 1000);
                        } else {
                              this.Verification = true;
                              this.$message({
                                    message: resData.msg,
                                    center: true,
                                    type: "error",
                              });
                        }
                  },
                  //修改手机号
                  async updateUserPhone() {
                        let data = {
                              phone: this.ruleForm.phone,
                              resetPhone: this.ruleForm.phone,
                              code: this.ruleForm.phonecode,
                        };
                        let resData = await this.$Api.Profile.updateUserPhone(data);
                        if (resData.code == 200) {
                              this.centerDialogVisible = false;
                              this.$message({
                                    message: "手机号修改成功",
                                    center: true,
                                    type: "success",
                              });
                        } else {
                              console.log("resData---", resData);
                              this.$message({
                                    message: resData.msg,
                                    center: true,
                                    type: "error",
                              });
                        }
                  },
                  //修改密码
                  async updateUserPassword() {
                        let that = this;
                        let data = {
                              password: this.ruleForm.originalPwd,
                              resetPassword: this.ruleForm.newPwd,
                        };
                        let resData = await this.$Api.Profile.updateUserPassword(data);
                        if (resData.code == 200) {
                              this.centerDialogVisible = false;
                              this.$message({
                                    message: "密码修改成功",
                                    center: true,
                                    type: "success",
                              });
                        } else {

                              // console.log('this.$refs',this.$refs.ruleForm)
                              // this.$refs.ruleForm.validateField("originalPwd");
                              // this.originalPwdShow = true;
                              // this.$refs[formName].validate((valid) =>{

                              // });
                              this.$message({
                                    message: resData.msg,
                                    center: true,
                                    type: "error",
                              });
                        }
                  },

                  //头像处理
                  errorimg(src) {
                        this.imageUrl = "https://yunapi.cnuzi.com/uploads/" + src;
                        // this.imageUrl = "https://apitest.lezhireading.com/uploads/" + src;
                        let that = this;
                        let img = new Image();
                        img.src = this.imageUrl;
                        img.onload = function () {
                              that.succeed = true;
                        };
                        img.onerror = function () {
                              that.succeed = false;
                        };
                  },
                  //输入框变化
                  judge(value) {
                        if (value.length == "") {
                              this.satisfy = false;
                        } else {
                              this.satisfy = true;
                        }
                  },
                  //单选框变化
                  touchRadio() {
                        this.satisfy = true;
                  },
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {},
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  let _this = this;
                  this.$AccessPortrait.Portrait("allow").then((res) => {
                        _this.userName = res.data.name;
                        _this.gender = res.data.sex;
                        _this.phone = res.data.resetphone;
                        _this.schoolName = res.data.schoolName;
                        _this.className =
                              res.data.className == null ? "暂无数据" : res.data.className;
                        _this.pwd = "******";
                        _this.errorimg(res.data.avatar);
                  });
            },
      };
</script>
<style lang='less' scoped>
      @import "./less/profile";
</style>